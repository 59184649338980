
import React, { useState } from 'react';
import { Dialog, Card, CardContent, CardActionArea, CardMedia } from "@mui/material";

function SelectSiteDialog({ open, sites, onClose, onSelectSite}) {

    const [siteselect,setSiteselect] = useState([]);

    const siteSelected = (sitename) =>{
        console.log("Site Name:", sitename);
        setSiteselect(sitename);

        if (onSelectSite) {
            onSelectSite(sitename);
        }
    }


    return (
        <Dialog open={open} onClose={onClose}>
            <div className="selectSiteDialog">
                <h1>Select a Site</h1>
                <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", marginTop: "20px" }}>
                    {sites.map((site) => (
                        <Card key={site.id} style={{ width: "170px" }}>
                            <CardActionArea onClick={() =>siteSelected(site)}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={site.site_img}
                                    alt={site.site_detail}
                                />
                                <CardContent>
                                    <h3 variant="h6">{site.site_name}</h3>
                                    <p>{site.site_detail}</p>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </div>
            </div>
        </Dialog>
    );
}

export default function CustomerLogin ({onLogin}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [emailalert, setEmailAlert] = useState('user_input');
    const [passalert, setPassAlert] = useState('user_input');
    const [sites, setSites] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [user, setUser] = useState([]);
    

   
  
    const handleLogin = async (e) => {
      e.preventDefault();
      try {
          const response = await fetch('http://localhost:5000/login', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email, password })
          });
          const data = await response.json();
          
          if (response.ok) {
                setUser(data.user);
                console.log("user:",data.user);
                setSites(data.sites);
                setDialogOpen(true);
          } else {
                setMessage(data.message); // แสดงข้อความผิดพลาด (ถ้ามี)
                setEmailAlert(data.emailalert);
                setPassAlert(data.passalert);
          }
      } catch (error) {
          console.error('Error:', error);
          setMessage('Something went wrong.');
      }
    };

    const handleSelectSite = (sitename) => {
        setDialogOpen(false);
        
        onLogin(user,sitename);
    };

    return (
    <div className="loginform">
        <form onSubmit={handleLogin}>
            
            <div className='form_head'>
                <h1 className='form_logo'>Habitat</h1>
                <p className='app_ver'>V0.0.1</p>
            </div>
            <input className={emailalert} type="email"  value={email} placeholder="Email"  onChange={(e) => setEmail(e.target.value)}></input>
            <input className={passalert} type="password"  value={password} placeholder="Password"  onChange={(e) => setPassword(e.target.value)}></input>
            <div className="Log_button">
                <button  type="submit" className="Log_btn">Log in</button>
            </div>
            <div className='msgTab'>
                {message && <p className='msgAlert'>{message}</p>}
            </div>
        </form>
        <SelectSiteDialog
                open={dialogOpen}
                sites={sites}
                onClose={() => setDialogOpen(false)}
                onSelectSite={handleSelectSite}
            />
    </div>
    );
}

