import React from 'react';

function InfoLogin({toggleForm, showLogin}) {
    return (
      <div className="info">
        <h1>Welcome to Habitat</h1>
        <p>If you don't have an account, please hit the button below</p>
        {showLogin && <a onClick={toggleForm}>Sign Up</a>}
      </div>
    );
  }

  export default InfoLogin;