import React, { useState, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateField } from '@mui/x-date-pickers/DateField';
import 'dayjs/locale/th';
import { ReactTabulator } from "react-tabulator"; 
import "react-tabulator/css/tabulator.min.css";
import 'react-tabulator/lib/css/tabulator.min.css';
import 'react-tabulator/lib/styles.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function MatReq ({user, site}) {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => setOpen(true);
    
    const [dep, setDep] = useState('Purchasing');
    const [data, setData] = useState([{ costCode: "",unit:"BHT.(บาท)", description: "", qty: 0, unitPrice: 0, totalAmount: 0 }]);
    const tabulatorRef = useRef(null);
    const [unitoptions, setunitOptions] = useState([]);

    useEffect(() => {
        fetch("http://localhost:5000/unit")
            .then((res) => res.json())
            .then((json) => {
                setunitOptions(json);
                console.log(json);
            })
            .catch((err) => console.error("Error fetching options:", err));
            
    }, []);


    

    const activetextother = () => {
        document.querySelector('.forOther').removeAttribute("disabled");
    };

    const disactivetextother = () => {
        document.querySelector('.forOther').setAttribute("disabled", "");
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setDep(value);
        if (value === 'Other') {
            activetextother();     
        } else {
            disactivetextother();
        }    
    };
    const handleClose = () => {
        
        setData((prevData) => [...prevData]);
        setOpen(false);
    };

    
    // ฟังก์ชันสำหรับจัดการเมื่อเซลล์ถูกแก้ไข
    const updateTotalAmount = (cell) => {
        console.log(cell);
        const row = cell.getRow();
        const rowData = row.getData();
        console.log(rowData);
        const qty = parseFloat(rowData.qty) || 0;
        const unitPrice = parseFloat(rowData.unitPrice) || 0;
        const totalAmount = qty * unitPrice;
        console.log(totalAmount);
        row.update({totalAmount}); // อัปเดตค่า Total Amount ในแถวที่แก้ไข
        tabulatorRef.current?.table?.redraw();
      };
    
    // ฟังก์ชันสำหรับเพิ่มแถวใหม่
    const addRow = () => {
        setData((prevData) => [
          ...prevData,
          { costCode: "", description: "",unit:"BHT.(บาท)", qty: 0, unitPrice: 0, totalAmount: 0 },
        ]);
      };

    // ฟังก์ชันสำหรับลดแถว
    const removeRow = () => {
        setData((prevData) => {
          if (prevData.length === 1) {
            return prevData;
          }
          return prevData.slice(0, -1);
        });
      };
    // ตั้งค่าเริ่มต้นของวันที่
    let date = new Date().toLocaleDateString("en-UK");

    const columns = [
        { title: "No.", field: "id", formatter: "rownum", hozAlign: "center", width: 60 },
        { title: "Cost Code", field: "costCode",editor: "list",width: 100},
        { title: "ABC", field: "abc",editor: "list",width: 100},
        { title: "WBS", field: "wbs",editor: "list",width: 150},
        { title: "Description", field: "description", editor: "input",width: 580 },
        { title: "Unit", field: "unit", editor: "list",width: 100, editorParams:{values: unitoptions.map(option => option.unit),dropdownOptions: {
            appendToBody: true, // บังคับ dropdown ให้อยู่ใน body
        },sort:"asc"},},
        { title: "Q'ty", field: "qty", editor: "input", hozAlign: "right", formatter: "money",  formatterParams: {decimal:".",  precision: 2 },
            cellEdited: function (cell) {
            //console.log("Cell Edited in Qty: ", cell.getData());
            updateTotalAmount(cell);
        }},
        { title: "Unit Price", field: "unitPrice", editor: "input", hozAlign: "right", formatter: "money", formatterParams: { decimal:".", precision: 2 },
            cellEdited: function (cell) {
            //console.log("Cell Edited in Unitrate: ", cell.getData());
            updateTotalAmount(cell);                       
        }},
        { title: "Total Amount",
          field: "totalAmount",
          formatter: "money",
          formatterParams: { decimal:".",symbol: "฿", precision: 2 },
          hozAlign: "right",
          bottomCalc:"sum",
          bottomCalcFormatter:"money",
          bottomCalcFormatterParams: {decimal:".",symbol: "฿", precision: 2},
        },
      ];

    return (
        <div className='mrScreen'>
            <div className='mrhead'>
                <div className='controlBut'>
                    <a className='newmrBut' onClick={handleClickOpen}>
                        <span className="material-symbols-rounded">add_circle</span>
                        <p>New MR</p>
                    </a>
                    <a className='setappBut'>
                        <span className="material-symbols-rounded">settings</span>
                        <p>set approval</p>
                    </a>
                </div>   
            </div>
            
            <Dialog id='mrdialog' open={open} onClose={handleClose}  maxWidth="xl">
                <div className='mkrnewmrDia'>
                    <h1>Material Request</h1>
                    <div className='toselection'>
                        <p className='mrfromtext'>From: {site.site_name}</p>
                        <p className='mrtotext'>To:</p>
                        <div className='selectionDep'>
                            <select value={dep} onChange={handleChange}>
                                <option value='Purchasing'>Purchasing</option>
                                <option value='CEC'>CEC</option>
                                <option value='CIC'>CIC</option>
                                <option value='Admin'>Admin</option>
                                <option value='IT'>IT</option>
                                <option value='Other'>Other</option>
                            </select>
                            <input className='forOther' disabled type='text' placeholder='for Other'></input>             
                        </div>
                        <div className='selectionReq'>
                            <p className='mrreqtext'>Request date:</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                                <DemoContainer className="container-datefield" components={['DatePicker']}>
                                    <DateField className="custom-datefield"  placeholder={date} disabled/>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        
                    </div>
                    <div className='mrno'>
                        <div className='mrno1'>
                            <p>MR No:  {site.site_name.slice(2,6)}</p>
                            <input type='text' placeholder='00000' maxLength={5}></input>
                        </div>
                        <p>Requestor: {user.name}</p>
                        <p>Tel: {user.tel}</p>
                    </div>
                    <div className='mrinfo'>
                        <div className='mrinfo1'>
                            <p>Subject:</p>
                            <input type='text'></input>
                            <p>Type:</p>
                            <select>
                                <option>Asset</option>
                                <option>Consumable</option>
                                <option>Main Materials</option>
                                <option>Sub Materials</option>
                                <option>Service</option>
                                <option>Small Tool</option>
                            </select>
                            <p>Require date:</p>
                            
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker className="custom-date" />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <div className='mrinfo2'>
                            <p>Note:</p>
                            <textarea rows="10" cols="100"></textarea>    
                        </div>
                    </div>
                    
                   
                    <div>
                        <button onClick={addRow} style={{ marginRight: "5px" }}>Add Row</button>
                        <button onClick={removeRow}>Remove Row</button>
                        
                    </div>
                    <div className='mrtable' >
                    <ReactTabulator
                        data={data}
                        columns={columns}
                        options={{
                            layout: "fitColumns", // ตั้งค่าการแสดงผล
                        }}
                        
                        height = "350px"
                    />
                    </div>
                    <a><p>Send Request</p></a>
                </div>
            </Dialog>
            
        </div>
    );
}

export default MatReq;