import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import CustomerLogin from './Login';
import NavbarApp from './navbar';
import InfoLogin from './infolLogin';
import CustomerSignup from './Signup';
import Home from './home';
import MatReq from './mr';

function App() {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLogin,setShowLogin] = useState(true);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [site, setsite] = useState(null);
    

    useEffect(() => {
      const loggedIn = localStorage.getItem("isLoggedIn");
      const userData = localStorage.getItem("userData");
      const storedSite = localStorage.getItem("siteData");
      

      if (loggedIn && userData && storedSite) {
          setUser(JSON.parse(userData));
          setsite(JSON.parse(storedSite));
          setIsLoggedIn(true);
      } else {
          navigate('/');
      }
    }, [navigate]);

    const toggleForm = () => {
        
      setShowLogin(!showLogin);
    };

    const handleLogout = () => {
      setUser(null);
      setsite(null);
      setIsLoggedIn(false);
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("userData");
      localStorage.removeItem("siteData");
      
      setIsLoggedIn(false);
      navigate('/');
    };

    const handleLogins = (userData,siteData) => {
      setUser(userData);
      setsite(siteData);
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("siteData", JSON.stringify(siteData));
      navigate('/home');
    };

    const gotoPage =(page) =>{
      if (page === 'homeBut'){
        navigate('/home'); 
      } else if(page === 'progressBut') {
        navigate('/progress');
      } else if(page === 'paymentBut') {
        navigate('/payment');
      } else if(page === 'reccostBut') {
        navigate('/reccordcost');
      } else if(page === 'repcostBut') {
        navigate('/repcordcost');
      } else if(page === 'abcwbsBut') {
        navigate('/abcwbs');
      } else if(page === 'planBut') {
        navigate('/plan');
      } else if(page === 'mrBut') {
        navigate('/mr');
      } else{
        console.log("page:", page);
      }
      
    }
        
    return (
    <div className="mainDisplay">
          {isLoggedIn ? <NavbarApp user={user} onLogout={handleLogout} page={(page)=>gotoPage(page)} /> : <div></div>}
          <Routes>
            <Route
              path='/'
              element ={isLoggedIn ?<Navigate to='/home' />:
                <div className="login_container">
                {showLogin ? <CustomerLogin onLogin={(userData,siteData) => handleLogins(userData,siteData)}/> : <CustomerSignup toggleForm={toggleForm}/>}
                <InfoLogin toggleForm={toggleForm} showLogin={showLogin}/>
                </div>
              }
            />
            <Route
              path='/home'
              element ={
                isLoggedIn ? <Home site={site}/> : <Navigate to="/" />
              }
            />
            <Route
              path='/progress'
              element ={
                <div><h1>Progress Page</h1></div>
              }
            />
            <Route
              path='/payment'
              element ={
                <div><h1>payment Page</h1></div>
              }
            />
            <Route
              path='/reccordcost'
              element ={
                <div><h1>record cost Page</h1></div>
              }
            />
            <Route
              path='/repcordcost'
              element ={
                <div><h1>report cost Page</h1></div>
              }
            />
            <Route
              path='/abcwbs'
              element ={
                <div><h1>abc wbs Page</h1></div>
              }
            />
            <Route
              path='/plan'
              element ={
                <div><h1>plan Page</h1></div>
              }
            />
            <Route
              path='/mr'
              element ={
                isLoggedIn ? <MatReq user={user} site={site}/> : <div></div>
              }
            />
          </Routes>        
    </div>
    );  
};



export default App;