import React, { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import Dialog from "@mui/material/Dialog";



function NavbarApp({user,onLogout,page}) {
  

  const handleLogout = () => {
    if (onLogout && typeof onLogout === "function" ) {
        setOpen(false);
        onLogout(); // ฟังก์ชัน Logout ถูกเรียกใช้อย่างถูกต้อง
    } else {
        console.error("onLogout is not a function or is undefined");
    }
  };

  const [activeButton,setactiveButton] = useState('homeBut');
  const [toggleButton,settoggleButton] = useState(false);
  
 


  
  const toggleactiveBut = (buttonName) =>{
    if (buttonName === 'costBut') {
      settoggleButton(!toggleButton); // toggle การแสดง subNav
      setactiveButton(buttonName); // Active ปุ่ม costBut
      page(buttonName);
    } else {
      setactiveButton(buttonName); // Active ปุ่มอื่นๆ
      if (buttonName==='reccostBut' || buttonName === 'repcostBut' || buttonName === 'abcwbsBut' ) {
        settoggleButton(true); // subNav ยังคงแสดงอยู่เมื่อคลิก subNav item
        page(buttonName);
      } else {
        settoggleButton(false); // ปิด subNav เมื่อคลิกปุ่มอื่นนอก subNav
        page(buttonName);
      }
    }
  };

  const [open, setOpen] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  



  return (
      <div className="navbarapp">
        <div className="userNav">
          <div className="userImg">
            <Avatar src={user.pictureURL} alt={user.name}></Avatar>
          </div>
          <div className="userInfo">
            <p className="positionInfo">{user.position === null ?'Position': user.position}</p>
            <p className="nameInfo">{`${user.name=== null?'name':user.name} ${user.lastName===null?'lastname':user.lastName}`}</p>
            <a className="logout_but" onClick = {handleClickOpen}>Log out</a>
          </div>
        </div>
        <div className="pageNav">
            <div className="mainNav">
              <ul>
                <li className={activeButton === 'homeBut' ? 'activeBut' : ''} onClick={() => toggleactiveBut('homeBut')}>
                  <a className="homeBut">
                    <span className="material-symbols-rounded">home</span>
                    <p>Home</p>
                  </a>
                </li>
                <li className={activeButton === 'progressBut' ? 'activeBut' : ''} onClick={() => toggleactiveBut('progressBut')}>
                  <a className="progressBut">
                    <span className="material-symbols-rounded">front_loader</span>
                    <p>Progress</p>
                  </a>
                </li>
                <li className={activeButton === 'paymentBut' ? 'activeBut' : ''} onClick={() => toggleactiveBut('paymentBut')}>
                  <a className="paymentBut">
                    <span className="material-symbols-rounded">payments</span>
                    <p>Payment</p>
                  </a>
                </li>
                <li className={toggleButton ? 'activeBut' : ''} >
                  <a className="costBut" onClick={() => toggleactiveBut('costBut')}>
                    <span className="material-symbols-rounded">savings</span>
                    <p>Cost</p>
                    <span className="material-symbols-rounded" id={toggleButton ? 'arrowDown':'arrowDownActive'}>keyboard_arrow_down</span>
                  </a>
                  <div className={toggleButton ? 'activeSub' : 'subNav'} >
                    <ul>
                      <li className={activeButton === 'reccostBut' ? 'activeBut' : ''} onClick={() => toggleactiveBut('reccostBut')}>
                        <a className="reccostBut">
                          <span className="material-symbols-rounded subicon">move_to_inbox</span>
                          <p>Record</p>
                        </a>
                      </li>
                      <li className={activeButton === 'repcostBut' ? 'activeBut' : ''} onClick={() => toggleactiveBut('repcostBut')}>
                        <a className="repcostBut">
                          <span className="material-symbols-rounded subicon">description</span>
                          <p>Report</p>
                        </a>
                      </li>
                      <li className={activeButton === 'abcwbsBut' ? 'activeBut' : ''} onClick={() => toggleactiveBut('abcwbsBut')}>
                        <a className="abcwbsBut">
                          <span className="material-symbols-rounded subicon">account_tree</span>
                          <p>Abc-Wbs Code</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className={activeButton === 'planBut' ? 'activeBut' : ''} onClick={() => toggleactiveBut('planBut')}>
                  <a className="planBut">
                    <span className="material-symbols-rounded">bar_chart</span>
                    <p>Planing</p>
                  </a>
                </li>
                <li className={activeButton === 'mrBut' ? 'activeBut' : ''} onClick={() => toggleactiveBut('mrBut')}>
                  <a className="mrBut">
                    <span className="material-symbols-rounded">storefront</span>
                    <p>Material Request</p>
                  </a>
                </li>
              </ul>
            </div>
        </div>
        <div>
          <Dialog open={open} onClose={handleClose}>
            <div className="logoutDialog">         
              <div className="closeDialogButton">
                <a className="closeButton" onClick = {handleClose}><span className="material-symbols-rounded">disabled_by_default</span></a>
              </div>
              <div className="logoutDialogTitle">
                <h1>logout of Habitat</h1>
              </div>
              <div className="logoutDialogContent">
                <p>Are you sure to logout?</p>
              </div>
              <div className="logoutDialogButton">
                <a onClick = {handleLogout}>Logout</a>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    
  );
}

export default NavbarApp;
