import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Card, CardContent, CardActionArea, CardMedia } from "@mui/material";
import ProjectChart from "./chart";



function Home({site}) {

  const [projectData, setProjectData] = useState([]);
  
  useEffect(() => {
    console.log(site.id);
    axios
      .get(`http://localhost:5000/api/project-data/${site.id}`)
      .then((response) => {
        console.log("API Response:", response.data);
        setProjectData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  }, [site.id]);
  
  console.log("Project Data:", projectData);

    return (
      <div className="homeSite">
        <div className="homeheader">
          <div className="homeSitecard">
              <Card key={site.id} style={{ width: "120px" }}>
                <CardMedia
                  component="img"
                  height="100"
                  image={site.site_img}
                  alt={site.site_detail}
                />
              </Card>
              <div className="homeSitecontent">
                <h1>{site.site_name}</h1>
                <p>{site.site_detail}</p>
              </div>
          </div>
          <div className="Plancard">
            <div className="Plancontent">
              <h1>Plan</h1>
              <div className="Planvalue">
                <h1 >- MB</h1>
              </div>
            </div>
          </div>
          <div className="Progresscard">
            <div className="Progresscontent">
              <h1>Progress</h1>
              <div className="Planvalue">
                <h1 >- MB</h1>
              </div>
            </div>
          </div>
          <div className="Paymentcard">
            <div className="Paymentcontent">
              <h1>Payment</h1>
              <div className="Planvalue">
                <h1 >- MB</h1>
              </div>
            </div>
          </div>
          <div className="Unbilledcard">
            <div className="Unbilledcontent">
              <h1>Unbilled</h1>
              <div className="Planvalue">
                <h1 >- MB</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="homebody">
          <div className='upcomingEvent'>
            <div className='calendar'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar />
              </LocalizationProvider>
            </div>
          </div>
          <div className='chart'>
            <ProjectChart data={projectData}/>
          </div>
          <div className='future'>

          </div>
        </div>
        
      </div>
    );
  }
  
  export default Home;