import React, { useState } from 'react';

function CustomerSignup ({toggleForm}) {

    

   
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const register = async (e) => {
        e.preventDefault();
    
        if (password !== confirmPassword) {
            setMessage("Passwords do not match");
            return;
        }
    
        try {
            const response = await fetch('http://localhost:5000/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, confirmPassword })
            });
    
            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);  // Successful registration message
            } else {
                setMessage(data.message);  // Error message
            }
        } catch (error) {
            setMessage("An error occurred. Please try again.");
        };
    } 

    return(
        <div className="signupform">
            <form onSubmit={register}>
                <div className="signuphead">
                    <span className="material-symbols-outlined">face</span>
                    <p>Filled it up to be new account</p>
                </div>
                
                <input className="user_input" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required></input>
                <input className="pass_input" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required></input>
                <input className="con_pass_input" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required></input>
                <div className="Sign_button">
                    <button className="Sign_btn" type="submit">Sign Up</button>
                    <a className="B_Log_btn" onClick={toggleForm}>Login</a>
                </div>
                <div className='msgTab'>
                {message && <p className='msgAlert'>{message}</p>}
                </div>
            </form>
        </div>
    );
 
}

export default CustomerSignup;