import React, { useState, useEffect } from 'react';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';

function ProjectChart({data}) {
    const [datachart, setdataChart] = useState([]);

    useEffect(()=>{
        setdataChart(data);
    }
    ,[data]);
    

    const series = [
      { type: 'line', dataKey: 'plan_cumulative', color: '#577399' },
      { type: 'bar', dataKey: 'plan_monthly', color: '#bfdbf7', yAxisId: 'rightAxis' },
    ];

    const otherSetting ={
        sx: {
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-20px)', 
            },[`& .${axisClasses.right} .${axisClasses.label}`]: {
                transform: 'translateX(20px)',
              }}
    }


  return (
      <div className='chartBox'>
          <ResponsiveChartContainer className='chartShow'
              dataset={datachart}
              series={series}
              xAxis={[
                  {
                      scaleType: 'band',
                      dataKey: 'month',
                      label: 'Month',
                  },
              ]}
              yAxis={[
                  { id: 'leftAxis', label: 'Cumulative', orientation: 'left' },
                  { id: 'rightAxis', label: 'Monthly', orientation: 'right' },
              ]}
              height={650}
              
              {...otherSetting}
          >
              
              <BarPlot />
              <LinePlot />
              <MarkPlot />
              <ChartsXAxis />
              <ChartsYAxis axisId="leftAxis" label="Cumulative:(MB)" />
              <ChartsYAxis axisId="rightAxis" position="right" label="Monthly:(MB)" />
              <ChartsTooltip />
          </ResponsiveChartContainer>
      </div>
  );
}

export default ProjectChart;